<template>
  <div class="scenesMap">
    <div id="map"></div>
    <sceneInfo></sceneInfo>
  </div>
</template>
<script>
import mapMixin from "@/mixins/mapMixin.vue";
import request from "@/utils/request";
import Tonglu from "@/api/home/Tonglu.json";
import sceneInfo from "./components/sceneInfo";
export default {
  name: "industryMap",
  mixins: [mapMixin],
  components: {
    sceneInfo,
  },
  data() {
    return {
      scenes: [],
    };
  },
  methods: {
    getSceneList() {
      request.post("/securityMap/getScenicSpotList").then((res) => {
        const data = res.data;
        if (typeof data == "string") {
          this.$msgError(data);
          return;
        }
        this.scenes = data.map((item) => {
          return {
            name: item.scenicSpotName,
            id: item.id,
          };
        });
        this.getscenicspotdata([data[0]]);
      });
    },
    // 获取景区数据
    getscenicspotdata(item) {
      // let Tongludata = Tonglu.features[0].geometry.coordinates[0][0];
      // let area = Tongludata.map((item) => {
      //   return new T.LngLat(item[0], item[1]);
      // });
      this.addAreaLayer(Tonglu.features[0].geometry.coordinates[0], {
        zoom: 10,
      });

      // let color = {
      //   color: "#ffffff",
      //   weight: 2,
      //   opacity: 0.8,
      //   fillColor: "#3366FF",
      //   fillOpacity: 0.5,
      // };
      // let polyArea = new T.Polygon(area, color);
      // this.setPoly(polyArea);
      // let icon = new T.Icon({
      //   iconUrl: require("@/assets/industryMap/scene-icon.png"),
      //   iconSize: new T.Point(30, 30),
      //   iconAnchor: new T.Point(30, 30),
      // });
      this.setLabelMarkers(
        item,
        require("@/assets/industryMap/scene-icon.png")
      );
      // this.setLabelJingqu(item);
    },
    // 设置景区点图标
    // setJingqu(coordinatesdata, config = null) {
    //   let _this = this;
    //   coordinatesdata.map((item) => {
    //     let lnglat = (item.coordinates || "").split(",");
    //     // console.log(lnglat)
    //     let marker = new T.Marker(
    //       new T.LngLat(lnglat[0].trim(), lnglat[1].trim()),
    //       config
    //     );
    //     let that = this;
    //     marker.addEventListener("click", () => {
    //       _this.$router.push({
    //         path: "/oneMap",
    //         query: {
    //           id: item.id,
    //           name: item.scenicSpotName,
    //         },
    //       });
    //     });
    //     this.setPoly(marker);
    //   });
    // },
    // 通过景区ID查询设施列表
    getSubMenu(menu) {},
    // 通过当前设施ID查询当前当前设施的设备信息
    gettwoMap(id) {},
  },
  mounted() {
    this.getSceneList();
    this.initMap();
  },
};
</script>
<style lang="less" scope>
.scenesMap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
#map {
  width: 100%;
  height: 100%;
}
</style>
