<template>
  <div class="infos">
    <div class="more" @click="$router.push('/industryMap/detail')">
      查看更多 >
    </div>
    <div class="infos-title">垂云通天河</div>
    <div class="infos-main">
      <div>运营单位：桐庐垂云通天河旅游公司</div>
      <div>安全风险评估单位：河南鑫安利职业健康科技有限公司</div>
      <div>建设地点：浙江省桐庐县瑶琳镇东琳村杨家</div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.infos {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  min-height: 20%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  padding: 13px;
  .more {
    position: absolute;
    right: 14px;
    top: 20px;
    color: #fff;
    width: 70px;
    font-size: 10px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid #fff;
    text-align: center;
    a {
      color: #fff;
    }
  }
  &-title {
    font-size: 17px;
    color: #fff;
    line-height: 24px;
    &::after {
      content: "";
      display: block;
      background-color: #fff;
      width: 50px;
      height: 3px;
      border-radius: 2px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &-main {
    font-size: 14px;
    line-height: 26px;
    color: #fff;
  }
}
</style>
